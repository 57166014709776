var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('validation-observer', {
    ref: "refFormObserver",
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var _vm$reservationsData;
        var invalid = _ref.invalid,
          pristine = _ref.pristine;
        return [_c('b-modal', {
          attrs: {
            "id": "modal-modify-add-travel-fly-passenger",
            "title": "Thêm thẻ thành viên",
            "title-class": "text-airline font-medium-4 font-weight-bolder",
            "centered": "",
            "size": "md",
            "no-close-on-backdrop": ""
          },
          on: {
            "show": _vm.showHandle
          },
          scopedSlots: _vm._u([{
            key: "modal-footer",
            fn: function fn(_ref2) {
              var cancel = _ref2.cancel;
              return [_c('div', {
                staticClass: "d-flex justify-content-end w-100"
              }, [_c('b-button', {
                staticClass: "center rounded-pill mr-2",
                attrs: {
                  "size": "md",
                  "variant": "outline-secondary"
                },
                on: {
                  "click": function click($event) {
                    return cancel();
                  }
                }
              }, [_vm._v(" " + _vm._s(_vm.$t('reservation.back')) + " ")]), _c('b-button', {
                directives: [{
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: 'rgba(113, 102, 240, 0.15)',
                  expression: "'rgba(113, 102, 240, 0.15)'",
                  modifiers: {
                    "400": true
                  }
                }],
                staticClass: "btn-gradient border-right-0",
                attrs: {
                  "pill": "",
                  "disabled": invalid || pristine && !_vm.createdNewMemberShipCard
                },
                on: {
                  "click": _vm.submitAddTravelFly
                }
              }, [_c('div', {
                staticClass: "d-flex-center px-25"
              }, [_vm._v(" " + _vm._s(_vm.$t('reservation.confirm')) + " ")])])], 1)];
            }
          }], null, true)
        }, [!_vm.isEmpty(_vm.passengerData) ? _c('b-card', {
          attrs: {
            "header-bg-variant": "light-info",
            "header-class": "py-1",
            "no-body": ""
          },
          scopedSlots: _vm._u([{
            key: "header",
            fn: function fn() {
              return [_c('span', {
                staticClass: "text-airline font-weight-bolder"
              }, [_vm._v(" " + _vm._s(_vm.passengerData.lastName) + " " + _vm._s(_vm.passengerData.firstName) + " " + _vm._s(_vm.passengerData.title) + " ")]), _c('small', {
                staticClass: "text-warning"
              }, [_vm._v("(" + _vm._s(_vm.$t("reservation.".concat(_vm.passengerData.paxType))) + ")")])];
            },
            proxy: true
          }], null, true)
        }, [_c('b-row', {
          staticClass: "mx-0 mt-75"
        }, [_c('b-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": "Hãng hàng không",
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                attrs: {
                  "label": _vm.$t('reservation.airline'),
                  "label-for": "memberFly-airline"
                }
              }, [_c('v-select', {
                staticClass: "membership-airline-select select-size-sm",
                staticStyle: {
                  "min-width": "180px"
                },
                attrs: {
                  "input-id": "memberFly-airline",
                  "options": _vm.getAirlineOptionsGroupByTrips(_vm.reservationsData),
                  "label": "text",
                  "clearable": false,
                  "placeholder": _vm.$t('reservation.placeholderSelect'),
                  "reduce": function reduce(val) {
                    return val.value;
                  }
                },
                scopedSlots: _vm._u([{
                  key: "option",
                  fn: function fn(data) {
                    return [_c('span', {
                      staticClass: "my-25 font-weight-bold",
                      class: _vm.isMobileView ? 'font-small-1' : 'font-small-4'
                    }, [_vm._v(" " + _vm._s(data.text) + " ")])];
                  }
                }, {
                  key: "selected-option",
                  fn: function fn(data) {
                    return [_c('span', {
                      staticClass: "font-weight-bolder text-nowrap",
                      class: _vm.isMobileView ? 'font-small-1' : 'font-small-4'
                    }, [_vm._v(" " + _vm._s(data.text) + " ")])];
                  }
                }], null, true),
                model: {
                  value: _vm.airline,
                  callback: function callback($$v) {
                    _vm.airline = $$v;
                  },
                  expression: "airline"
                }
              }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('reservation.cardNumber'),
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                attrs: {
                  "label": _vm.$t('reservation.cardNumber'),
                  "label-for": "memberFly-number"
                }
              }, [_c('b-form-input', {
                staticClass: "font-medium-1 text-dark font-weight-bold",
                attrs: {
                  "id": "memberFly-number",
                  "lazy-formatter": "",
                  "formatter": _vm.trimInput,
                  "placeholder": _vm.$t('reservation.placeholderInput'),
                  "state": _vm.getValidationState(validationContext) === false ? false : null
                },
                model: {
                  value: _vm.newCSV,
                  callback: function callback($$v) {
                    _vm.newCSV = $$v;
                  },
                  expression: "newCSV"
                }
              }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1), _vm.createdNewMemberShipCard ? _c('div', {
          staticClass: "px-1"
        }, [_vm._l(_vm.createdNewMemberShipCard, function (ttv, indexTtv) {
          return _c('div', {
            key: indexTtv,
            staticClass: "mb-50"
          }, [ttv.cardNumber === 0 || !_vm.isNumber(ttv.cardNumber) ? _c('div', {
            staticClass: "text-center"
          }, [_c('span', {
            staticClass: "text-danger font-weight-bolder"
          }, [_vm._v(" - " + _vm._s(_vm.$t('reservation.errCreateMembershipCardAirline')) + " " + _vm._s(ttv.airline.label) + ": " + _vm._s(ttv.msgError ? _vm.getErr(ttv.msgError) : '') + " ")])]) : _c('div', [_c('small', {
            staticClass: "text-success"
          }, [_vm._v(" - " + _vm._s(_vm.$t('reservation.newMembershipCreated', {
            airline: "".concat(ttv.airline.label)
          })))]), _c('div', {
            staticClass: "text-dark font-medium-2 font-weight-bolder ml-1"
          }, [_vm._v(" " + _vm._s("".concat(ttv.cardNumber)) + " ")])])]);
        }), _c('b-alert', {
          staticClass: "mt-1",
          attrs: {
            "variant": "warning",
            "show": ""
          }
        }, [_c('div', {
          staticClass: "alert-body text-center"
        }, [_c('span', {
          staticClass: "font-medium-1 text-airline"
        }, [_vm._v(_vm._s(_vm.$t('reservation.successCreateTTV')))])])])], 2) : _vm._e()], 1), !['VJ'].includes((_vm$reservationsData = _vm.reservationsData) === null || _vm$reservationsData === void 0 ? void 0 : _vm$reservationsData.source) ? _c('b-row', {
          staticClass: "mb-1 d-flex-center"
        }, [_c('span', {
          staticClass: "mr-75"
        }, [_vm._v(_vm._s(_vm.$t('reservation.dontHaveMembershipCardYet')))]), _c('b-button', {
          attrs: {
            "variant": "flat-info",
            "size": "sm",
            "pill": ""
          },
          on: {
            "click": function click($event) {
              return _vm.handleOpenModalCreateMemberShipCard($event);
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('reservation.createNew')) + " ")])], 1) : _vm._e()], 1) : _c('b-spinner')], 1)];
      }
    }])
  }), _c('ModalRegisterMembershipCard', {
    attrs: {
      "member-fly-data": _vm.memberFlyData,
      "data-search-flight": _vm.dataSearchFlight,
      "airline-lists": _vm.getAirlineList,
      "pax-data": _vm.passengerData,
      "place": 'modify'
    },
    on: {
      "update:memberFlyData": function updateMemberFlyData($event) {
        _vm.memberFlyData = $event;
      },
      "update:member-fly-data": function updateMemberFlyData($event) {
        _vm.memberFlyData = $event;
      }
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }